import { Component, OnInit } from '@angular/core';
import { AppointmentService } from '../../../../services/appointment.service';
import { ToastrService } from 'ngx-toastr';
import { SortingType } from '../sort.enum';
import { TerminGetAll } from '../../../../models/termin.model';
import { PaginationComponent } from '../../pagination/pagination.component';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TerminStatus } from '../../../../models/enums/terminStatus.enum';
import { AuthService } from '../../../../services/auth.service';
import { VehicleType } from '../../../../models/enums/vehicleType.enum';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


interface PageData {
  pageSize: number;
  date: string;
  currentPage: number;
  search: string;
  sortType: SortingType;
}

@Component({
  selector: 'app-termini-pregled',
  standalone: true,
  imports: [CommonModule, PaginationComponent, FormsModule,NgbDatepickerModule],
  templateUrl: './termini-pregled.component.html',
  styleUrl: './termini-pregled.component.scss',
  providers: [DatePipe] 
})
export class TerminiPregledComponent implements OnInit {
  public data: TerminGetAll[] = [];
  pageData: PageData = {
    pageSize: 0,
    date: new Date().toString(),
    currentPage: 1,
    search: '',
    sortType: SortingType.Starije,
  };
  vidiSlobodne : boolean = true;
  TerminStatus = TerminStatus;
  VehicleType = VehicleType;

  constructor(private datePipe: DatePipe, private authService: AuthService, private appointmentService: AppointmentService, private toastr: ToastrService, private router: Router) {

  }
  ngOnInit(): void {
    const currentDate = new Date();
    this.onDateChange({
      year: currentDate.getFullYear(),
      month: String(currentDate.getMonth() + 1).padStart(2, '0'),
      day: String(currentDate.getDate()).padStart(2, '0'),
    });
  }

  navigate(dateNavigate: Date){
    let formattedDate = this.datePipe.transform(dateNavigate, 'yyyy-MM-ddTHH:mm:ss.SSS');

    this.router.navigate(['online-zakazivanje/'+formattedDate+'Z']).then(() => {
      window.scrollTo(0, 0);
    });
  }
  
  onDateChange(selectedDate: any) {
    if(selectedDate)
      this.pageData.date = `${selectedDate.year}-${selectedDate.month.toString().padStart(2, '0')}-${selectedDate.day.toString().padStart(2, '0')}`;

    this.appointmentService.getAllAdmin(this.pageData).subscribe((response: any) => {
      if (response.status == 200) {
        this.data = response.data.data;
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }

  isAdmin() {
    return this.authService.isRole('Admin')
  }


  approveAppointment(status: TerminStatus, id: number) {
    this.appointmentService.approveAppointment({ status: status, id: id }).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.onDateChange(null);
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
}
