import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss','./login-mobile.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router) {

  }
  ngOnInit(): void {
    if (this.authService.isLoggedIn()){
      this.router.navigate(['panel/vozila']);
    }
  }
  public user: any = {
    name: '',
    password: '',
  }
  navigate (route : string)
  {
this.router.navigate([route]).then(() => {
    window.scrollTo(0, 0);
});
  }
  passwordFieldType: string = 'password';

  togglePasswordVisibility(): void {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
  public login() {
    //VALIDATION FIELDS
    if (this.user.password.length  < 8) {
      this.toastr.error('Molimo unesite ispravnu lozinku.');
      return;
    }

    //CALL BACK
    this.authService.login(this.user).subscribe((response: any) => {
      console.log(response);
      if (response.status == 200) {
        localStorage.setItem('token', response.data)
        this.toastr.success("Uspešno ste ulogovani.")
          this.router.navigate(['panel/vozila']);
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }

}
