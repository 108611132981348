<div *ngIf="modal" class="modal" (click)="toggleModal()">
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div class="tab-header">
            <span class="title">CENA REGISTRACIJE</span>
            <div class="btn-close-position" (click)="toggleModal()">
                <div class="btn-close-custom">
                    <img src="../../../../assets/icons/close.svg">
                </div>
            </div>
        </div>
        <p><i>Cena registracije je okvirna, te zbog cestih promena može doći do sitnih odstupanja u odnosu na ispravnu
                cenu.</i></p>
        <div class="body-table">
            <table>
                <thead>
                    <tr>
                        <th>Usluga</th>
                        <th>Cena</th>
                    </tr>
                </thead>
                <tbody class="tbody">
                    @if(this.rezultat.polisa != 0)
                    {
                    <tr>
                        <td>Polisa</td>
                        <td>{{this.rezultat.polisa + this.rezultat.premijski}} rsd</td>
                    </tr>
                    }
                    @if(this.rezultat.komunalna != 0)
                    {
                    <tr>
                        <td>Komunalna taksa</td>
                        <td>{{this.rezultat.komunalna}} rsd</td>
                    </tr>
                    }
                    @if(this.rezultat.porez != 0)
                    {
                    <tr>
                        <td>Porez</td>
                        <td>{{this.rezultat.porez}} rsd</td>
                    </tr>
                    }
                    @if(this.rezultat.zeleniKarton != 0)
                    {
                    <tr>
                        <td>Zeleni karton</td>
                        <td>{{this.rezultat.zeleniKarton}} rsd</td>
                    </tr>
                    }
                    @if(this.rezultat.ratSaobracajna != 0)
                    {
                    <tr>
                        <td>Saobraćajna</td>
                        <td>{{this.rezultat.ratSaobracajna}} rsd</td>
                    </tr>
                    }
                    @if(this.rezultat.ratTablice != 0)
                    {
                    <tr>
                        <td>Tablice</td>
                        <td>{{this.rezultat.ratTablice}} rsd</td>
                    </tr>
                    }

                    @if(this.rezultat.rentCar != 0)
                    {
                    <tr>
                        <td>Rent a car</td>
                        <td>{{this.rezultat.rentCar}} rsd</td>
                    </tr>
                    }
                    @if(this.rezultat.invalid != 0)
                    {
                    <tr>
                        <td>Invalid</td>
                        <td>{{this.rezultat.invalid}} rsd</td>
                    </tr>
                    }
                    <tr>
                        <td>RAT zahtev - nalepnica</td>
                        <td>400 rsd</td>
                    </tr>
                    <tr>
                        <td>NBS - Nalepnica</td>
                        <td>236 rsd</td>
                    </tr>
                    <tr>
                        <td>Registraciona nalepnica RAT</td>
                        <td>250 rsd</td>
                    </tr>
                    @if(this.rezultat.tehnicki != 0)
                    {
                    <tr>
                        <td>Tehnički pregled</td>
                        <td>{{this.rezultat.tehnicki}} rsd</td>
                    </tr>
                    }
                    @if(this.rezultat.odlazakSup != 0)
                    {
                    <tr>
                        <td>Usluga odlaska u SUP</td>
                        <td>{{this.rezultat.odlazakSup}} rsd</td>
                    </tr>
                    }
                    @if(this.rezultat.pripremaDokumentacije != 0)
                    {
                    <tr>
                        <td>Izdavanje nalepnice</td>
                        <td>{{this.rezultat.pripremaDokumentacije}} rsd</td>
                    </tr>
                    }
                </tbody>
                <tr>
                    <td class="sum">UKUPNO</td>
                    <td class="sum">{{this.rezultat.sum}} rsd</td>
                </tr>
            </table>
        </div>
        <br>
    </div>
</div>

<div *ngIf="image" class="modal" (click)="toggleModalimage(0)">
    <div class="modal-content" (click)="$event.stopPropagation()">

        <div class="tab-header">
            <span class="title"> </span>
            <div class="btn-close-position" (click)="toggleModalimage(0)">
                <div class="btn-close-custom">
                    <img src="../../../../assets/icons/close.svg">
                </div>
            </div>
        </div>
        @if(imagemap == 1)
        {
        <img class="imgshow" src='../../../../../assets/images/kalkulator/p1.png' />
        }
        @if(imagemap == 2)
        {
        <img class="imgshow" src='../../../../../assets/images/kalkulator/p2.png' />
        }
        @if(imagemap == 3)
        {
        <img class="imgshow" src='../../../../../assets/images/kalkulator/pstepen.jpg' />
        }
        @if(imagemap == 4)
        {
        <span class="descshow">Broj šteta u poslednjih godinu dana izazvanih Vašom krivicom</span>
        }
    </div>
</div>


<div class="banner">
    <span class="title">Cena registracije motora</span>
    <span class="content">Registracijom vozila u našem auto centru, završavate sve na jednom mestu i u jednom
        danu.</span>
</div>
<div class="calculator">
    <div class="row">
        <form class="row" #f="ngForm" (ngSubmit)="calculate(f.valid)">
            <div class="col">
                <label class="labelPopup">Godište</label>
                <input class="form-control" min="1900" type="number" name="godiste" [(ngModel)]="data.godiste"
                    #godiste="ngModel" required autocomplete="off" />
                @if(f.submitted && godiste.invalid){
                <div class="form-control-error">Godište je obavezno polje</div>
                }

                <br>

                <div class="text">
                    <label class="labelPopup">Zapremnina</label> <span class="opis" (click)="toggleModalimage(1)">ⓘ Gde
                        da vidim zapremninu?</span>
                </div>
                <input class="form-control" min="1" type="number" name="zapremnina" [(ngModel)]="data.zapremnina"
                    #zapremnina="ngModel" required autocomplete="off" />
                @if(f.submitted && zapremnina.invalid){
                <div class="form-control-error">Zapremnina je obavezno polje</div>
                }

                <br>

                <div class="form-col-50">
                    <div class="text">
                        <label class="labelPopup">Premijski stepen</label> <span class="opis"
                            (click)="toggleModalimage(3)">ⓘ Gde da vidim premijski stepen?</span>
                    </div>
                    <select class="form-control" name="premijskiStepen" [(ngModel)]="data.premijskiStepen"
                        #premijskiStepen="ngModel" required autocomplete="off">
                        <option [ngValue]=1>1 [-25%]</option>
                        <option [ngValue]=2>2 [-15%]</option>
                        <option [ngValue]=3>3 [-5%]</option>
                        <option [ngValue]=4>4 [0%]</option>
                        <option [ngValue]=0>Prva registracija</option>
                        <option [ngValue]=5>5 [+15%]</option>
                        <option [ngValue]=6>6 [+30%]</option>
                        <option [ngValue]=7>7 [+50%]</option>
                        <option [ngValue]=8>8 [+70%]</option>
                        <option [ngValue]=9>9 [+90%]</option>
                        <option [ngValue]=10>10 [+110%]</option>
                        <option [ngValue]=11>11 [+130%]</option>
                        <option [ngValue]=12>12 [+150%]</option>
                    </select>
                </div>
                <br>

                <div class="form-col-50">
                    <div class="text">
                        <label class="labelPopup">Koliko šteta ste imali?</label> <span class="opis"
                            (click)="toggleModalimage(4)">ⓘ Šta ovo znači?</span>
                    </div>
                    <select class="form-control" name="brojSteta" [(ngModel)]="data.brojSteta" #brojSteta="ngModel"
                        required autocomplete="off">
                        <option [ngValue]=0>0</option>
                        <option [ngValue]=1>1</option>
                        <option [ngValue]=2>2</option>
                        <option [ngValue]=3>3</option>
                        <option [ngValue]=4>4</option>
                        <option [ngValue]=5>5</option>
                    </select>
                </div>


            </div>
            <div class="col">

                <label class="labelPopup">Usluge</label>
                <div class="row-checkbox"><input type="checkbox" name="tehnickiPregled"
                        [(ngModel)]="data.tehnickiPregled" #tehnickiPregled="ngModel" />
                    <span>Tehnički pregled</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="izdavanjeNalepnice" [(ngModel)]="data.odlazakSup"
                        #izdavanjeNalepnice="ngModel" />
                    <span>Odlazak u SUP</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="tabliceSaobracana"
                        [(ngModel)]="data.pripremaDokumentacije" #pripremaDokumentacije="ngModel" />
                    <span>Priprema dokumentacije</span>
                </div><br>
                <label class="labelPopup">Ostali podaci o vozilu</label>
                <div class="row-checkbox"><input type="checkbox" name="invalid" [(ngModel)]="data.invalid"
                        #invalid="ngModel" />
                    <span>Vlasnik vozila je invalid</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="rentCar" [(ngModel)]="data.rentCar"
                        #rentCar="ngModel" />
                    <span>Vozilo je Rent a car</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="saobracajna" [(ngModel)]="data.saobracajna"
                        #saobracajna="ngModel" />
                    <span>Potrebna mi je saobraćajna</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="tablice" [(ngModel)]="data.tablice"
                        #tablice="ngModel" />
                    <span>Potrebne su mi tablice</span>
                </div>
                <div class="row-checkbox"><input type="checkbox" name="zeleniKarton" [(ngModel)]="data.zeleniKarton"
                        #zeleniKarton="ngModel" />
                    <span>Potreban mi je zeleni karton</span>
                </div>
            </div>
            <button type="submit" style="margin-top:10px;" class="button-sucess">IZRAČUNAJ REGISTRACIJU</button>
        </form>
    </div>
</div>