<div class="row">
  <div class="search-bar">
    <div>
      <select name="sort" id="sort" [(ngModel)]="pageData.sortType" (change)="reloadTable()">
        <option [ngValue]="types.Podrazumevano">Podrazumevano</option>
        <option [ngValue]="types.Novije">Novije</option>
        <option [ngValue]="types.Starije">Starije</option>
      </select>
    </div>
    <input class="datefilter" type="date" name="date" (change)="setDate($event)">
  </div>
</div>
<div class="row">
  <div class="appointemnts">
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>Vreme</th>
            <th>Tip vozila</th>
            <th>Vlasnik</th>
            <th>Vozilo</th>
            <th>Kontakt</th>
            <th>Status</th>
            <th *ngIf="isAdmin()"></th>
            <th *ngIf="isAdmin()"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <td>Datum: <b>{{ item.time | date:'dd.MM.yyyy' }}</b><br>Vreme: <b>{{ item.time | date:'HH:mm' }}</b></td>
            <td>@if(item.tipVozila == VehicleType.Putnicko_vozilo)
              { <img class="icon" src="../../../../assets/icons/putnickoptype.svg">}
              @else{
              @if(item.tipVozila == VehicleType.Motor){
              <img class="icon" src="../../../../assets/icons/motorbiketype.svg">
              }
              @else {<img class="icon" src="../../../../assets/icons/truck.svg">}
              }
            </td>
            @if(item.ime != '' && item.prezime != '')
            {
            <td>Ime: <b>{{ item.ime }}</b> <br>Prezime: <b>{{ item.prezime }}</b> </td>
            }
            @else {
            <td>Firma: <b>{{item.companyName}}</b> <br> PIB: <b>{{item.pib}}</b></td>
            }
            <td>{{ item.vozilo }}</td>
            <td>{{ item.telefon }} <br> {{ item.email }}</td>
            <td><span [ngClass]="{
              'cekanje': item.status == 1,
              'odbijen': item.status == 2,
              'odobren': item.status == 3,
              'zavrsen': item.status == 4,
              'otkazao': item.status == 6,
              'nijesepojavio': item.status == 5,
          }">{{ TerminStatus[item.status].toString().replaceAll('_',' ') }}</span></td>

            <td class="actions" *ngIf="isAdmin() && item.status == TerminStatus.Na_cekanju">
              <div class="icon-div"
                (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Odobren, item.id)"><img class="icons"
                  title="Potvrdi vozilo" src="../../../../assets/icons/approve.svg" /><span>Odobri</span></div>
              <div class="icon-div"
                (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Odbijen, item.id)"><img class="icons"
                  title="Odbij vozilo" src="../../../../assets/icons/reject.svg" /><span>Odbij</span></div>

            </td>
            <td class="actions" *ngIf="isAdmin() && item.status == TerminStatus.Odobren">
              <div class="icon-div"
                (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Zavrsen, item.id)"><img class="icons"
                  title="Pojavio se" src="../../../../assets/icons/approve.svg" /><span>Pojavio se</span></div>
              <div class="icon-div"
                (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Nije_se_pojavio, item.id)"><img
                  class="icons" title="Nije se pojavio" src="../../../../assets/icons/reject.svg" /><span>Nije se
                  pojavio</span></div>
              <div class="icon-div"
                (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Otkazao, item.id)"><img
                  class="icons" title="Otkazao" src="../../../../assets/icons/reject.svg" /><span>Otkazao</span></div>
            </td>
            <td class="actions"
              *ngIf="isAdmin() && (item.status == TerminStatus.Odobren || item.status == TerminStatus.Na_cekanju)">

            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row-table">
      <app-pagination [pageSize]="pageData.pageSize" [currentPage]="pageData.currentPage" [count]="count"
        (pageChange)="pageChange($event)">
      </app-pagination>
    </div>
  </div>
</div>