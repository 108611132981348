import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../config/config';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private pathApi = this.config.setting['pathApi'] + "appointment/";

  constructor(private config: AppConfig, private http: HttpClient, private toastr: ToastrService) { }

  save(obj : any) {
    return this.http.post(this.pathApi + 'save', obj);
  }
  approveAppointment(obj : any) {
    return this.http.post(this.pathApi + 'approveAppointment', obj);
  }
  getAll(obj : any) {
    return this.http.post(this.pathApi + 'getAll', obj);
  }
  getAllAdmin(obj : any) {
    return this.http.post(this.pathApi + 'getAllAdmin', obj);
  }
  getFreeForDate(date : Date) {
    return this.http.get(this.pathApi + 'getFreeForDate/'+ date.toISOString().substring(0, 10));
  }
}
