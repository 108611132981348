<app-side-private-mobile-menu [isVisible]="modalVisible" (togleModal)="togleModal()"></app-side-private-mobile-menu>
<div class="header">
  <div class="header-logo" (click)="navigate('/')"><img class="logo" src="../../../../assets/images/accuburac.png"/></div>
  <div class="header-menu">
    <ul>
      <li (click)="navigate('/panel/vozila')">Moja vozila</li>
      @if (!isAdmin()) {
        
        <li (click)="navigate('/panel/termini')">Moji termini</li>
      }@else {
          <li (click)="navigate('/panel/termini-svi')">Termini</li>

        }
      @if (isAdmin()) {

      <li (click)="navigate('/panel/referal')">Referal</li>
      }
      <li (click)="navigate('/panel/kalkulator-registracije')">Cena registracije</li>
      @if (isAdmin()) {
      <li (click)="navigate('/panel/korisnici')">Korisnici</li>
      }
    </ul>
    <img (click)="togleModal()" class="icon-menu" src="../../../../assets/icons/hamburgermenu.svg"/>
  </div>
  <div class="header-right">
    <p class="button-sucess withicon"> 
      @if (!isLoggedIn()) {<img class="only-icon" src="../../../../assets/icons/login.svg" (click)="navigate('/login')"/><span title="Prijava na sistem" (click)="navigate('/login')">PRIJAVA</span>}
      @if (isLoggedIn()) {<img class="only-icon" src="../../../../assets/icons/logout-white.svg" (click)="$event.stopPropagation() ; logout()"/><span title="Odjavi se" (click)="$event.stopPropagation() ; logout()">ODJAVI SE</span>}  
    </p>
    <p title="Zakazivanje termina za tehnički" class="button-sucess" (click)="openTab(1)">ZAKAZIVANJE ONLINE</p>
  </div>
</div>