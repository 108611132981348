export class ShedulingData {
    firstName: string = ""
    lastName: string = ""
    phone:string = "";
    email:string = "";
    comment:string = "";
    companyName:string = "";
    pib:string = "";
    isBusiness:boolean = false;
    // auto
    vozilo: string = "";
    tipVozila: number = 0;
    VehicleId: number = 0;
    // termin
    datum : Date = new Date(0,0,0);
    tempdatum : Date = new Date(0,0,0);
}