@if(isVisible){
<div id="fsModal" class="modal animated bounceIn" tabindex="-1" (click)="handleClick()">
    <!-- dialog -->
    <div class="modal-dialog">
        <!-- content -->
        <div class="modal-content">
            <div class="header-modal">
                <div class="close" (click)="handleClick()">
                    <img class="close-img" src="../../../../../assets/icons/close.svg" />
                </div>
            </div>
            <!-- body -->
            <div class="modal-body">
                <div class="menu-item" (click)="navigate('/')">
                    <img src="../../../../../assets/icons/home.svg" />
                    Pocetna
                </div>
                <div class="menu-item" (click)="navigate('panel/vozila')">
                    <img src="../../../../../assets/icons/registration.svg" />
                    Moja vozila
                </div>
                @if (!isAdmin()) {
                    @if (!isAdmin()) {
                        <div class="menu-item" (click)="navigate('panel/termini')">
                          <img src="../../../../../assets/icons/calendar.svg" />
                          Moji termini
                        </div>
                
                      }@else {
                        <div class="menu-item" (click)="navigate('panel/termini-svi')">
                            <img src="../../../../../assets/icons/calendar.svg" />
                            Termini
                          </div>
                
                        }
            
                  }@else {
                    <div class="menu-item" (click)="navigate('panel/termini-svi')">
                      <img src="../../../../../assets/icons/calendar.svg" />
                      Termini
                    </div>
            
                    }
                <div class="menu-item" (click)="navigate('panel/referal')">
                    <img src="../../../../../assets/icons/user-dark.svg" />
                    Referal
                </div>
                <div class="menu-item" (click)="navigate('panel/kalkulator-registracije')">
                    <img src="../../../../../assets/icons/calculator.svg" />
                    Kalkulator registracije
                </div>
                @if (isAdmin()) {
                <div class="menu-item" (click)="navigate('panel/korisnici')">
                    <img src="../../../../../assets/icons/calendar.svg" />
                    Korisnici
                </div>}
                <div class="menu-item" (click)="navigateId('/','contact')">
                    <img src="../../../../../assets/icons/contact.svg" />
                    Kontakt
                </div>
                <div class="menu-item" (click)="logout()">
                    <img src="../../../../../assets/icons/logout.svg" />
                    Odjavi se
                </div>
            </div>
            <!-- body -->
        </div>
        <!-- content -->
    </div>
    <!-- dialog -->
</div>

}